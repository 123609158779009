import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
const Korkortstillstand = (props) => (
  <Layout>
    <Seo title='Körkortstillstånd' />
    <PageHeader text='Körkortstillstånd' />
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-sm-12 col-md-12col-lg-12 col-xl-12 mt-5'>

          <div>
            <h3>När får man ansöka och hur länge gäller det ?</h3>
            <p>
              Övningskörning med personbil är tillåten när du fyllt 16 år och har ett giltigt körkortstillstånd.
              Även om du enbart ska övningsköra på trafikskola måste du ha körkortstillstånd.
              Körkortstillståndet består av två delar, hälsodeklaration och synintyg.
              Körkortstillståndet gäller i 5 år och går inte att förlänga.
            </p>

          </div>

          <div>
            <h3>Hälsodeklarationen</h3>
            <p>
              Kan i dag göras direkt via körkortsportalens e-tjänst.
              Du kan okså beställa hem pappersblanketter till din folkbokföringsadress.
              Hälsodeklarationen består av 16 frågor.
            </p>

          </div>

          <div className='row'>
            <div className='col-12 col-sm-12 col-md-8 col-lg-6 col-xl-7'>
              <h3>Synintyget</h3>
              <p>Kan vi här på trafikskolan hjälpa till att komplettera med. Vi rapporterar in resultatet direkt till transportstyrelsen.
                <br /> <br />Även optiker alternativt läkare kan också utfärda synintyg.
                Men var beredd på att alla inte är ansluta till e-tjänsten,
                vilket gör att du eventuellt måste ha med dig pappersblanketterna för synintyg,
                för att senare kunna komplettera via post.

              </p>
            </div>
            <div className='col-12 col-sm-12 col-md-3 col-lg-4 col-xl-5 mt-md-5 mt-lg-5 mt-xl-3 mt-sm-1 text-center mb-5'>
              {/* <img src={require('../../images/information/synundersokning.jpg')} alt='synundersokning' className='img-fluid' /> */}
              <Img fluid={props.data.imageOne.childImageSharp.fluid} alt='synundersokning' className='rounded' />
            </div>
          </div>

          <h3>Beställ blankett eller ansök direkt via e-tjänsten</h3>
          <p>
            Din ansökan kan du betala direkt via din internetbank eller med betalkort.
            Du kan även få ett inbetalningskort skickat hem till dig.
          </p>

          <div className='row justify-content-center'>
            <div className='col-auto col-sm-auto col-md-12 col-lg-12 col-xl-12'>
              <table className='table-borderless table table-responsive'>
                <tbody>
                  <tr>
                    <td>
                      <a href='https://www.transportstyrelsen.se/sv/vagtrafik/e-tjanster-och-blanketter/e-tjanster-inom-vagtrafik/?tab=korkort&eservice=korkortstillstand(grupp1)-ansok#eservice130157' className='btn btn-danger active' role='button' aria-pressed='true' target='blank'>Via E-tjänsten</a>
                    </td>
                    <td>
                      <a href='https://korkorttjanster.transportstyrelsen.se/BestallaBlankett' className='btn btn-danger active' align='left' role='button' aria-pressed='true' target='blank'>Beställ med post</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div>
            <br />
            <h3>Handläggningstider</h3>
            {/* <img className='text-center' src={require('../../images/information/transportstyrelsen.png')} alt='transportstyrelsen' /> */}
            <Img fixed={props.data.imageTwo.childImageSharp.fixed} alt='transportstyrelsen' className='rounded' />
            <p>
              Efter att ansökan kommit till Transportstyrelsen tar det mellan 1 till 6 veckor innan du får ditt körkortstillstånd hemskickat till dig.
              Hur snabbt ditt ärende handläggs av Transportstyrelsen beror på vad ärendet gäller, hur du har ansökt och hur komplett din ansökan är.
            </p>
            <p>
              Innan Transportstyrelsen kan börja pröva din ansökan om körkortstillstånd måste
            </p>
            <ul>
              <li>Ditt synintyg ha skickats in till Transportstyrelsen </li>
            </ul>
          </div>

          <div>
            <h4> Observera </h4>
            <p>
              Ett giltigt körkortstillstånd inte ger dig rätten att köra hemma privat med handledare.
              För att du ska kunna få göra detta måste du och din privata handledare också genomgå en introduktionsutbildning.
            </p>

          </div>

        </div>
      </div>
    </div>
  </Layout>
)

export default Korkortstillstand

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "information/synundersokning.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 10000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    imageTwo: file(relativePath: { eq: "information/transportstyrelsen.png" }) {
      childImageSharp {
        fixed(width: 172, height: 62) {
          ...GatsbyImageSharpFixed
        }
      }
    }
} 
`
